//

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { MainLayout, SubpageHeader, createMetaData } from '../components';

import About from '../components/About';
import CallToAction from '../components/CallToAction';

const PageAbout = ({ location, pageContext }) => {
	const { pageAboutJson: json } = useStaticQuery(graphql`
		query {
			pageAboutJson {
				meta_title
				meta_description
				meta_keywords

				title
				subtitle
				subsubtitle
				lead

				permalink

				type
			}
		}
	`);
	// console.log('pageContext ', pageContext);
	// console.log('location ', location);
	const { site, page, seo } = createMetaData(json);
	// console.log('pages/about; site ', site, ' page ', page, ' seo ', seo);

	return (
		<MainLayout site={site} page={page} seo={seo}>
			<SubpageHeader page={page} />
			<About />
			{/* <CallToAction /> */}
		</MainLayout>
	);
};

export default PageAbout;
