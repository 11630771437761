
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const About = () => {
	const { contentAboutJson: about	} = useStaticQuery(graphql`
		query {
			contentAboutJson {
				title
				subtitle
				text {
					id
					text
				}
				images {
					id
					image {
						childImageSharp {
							gatsbyImageData(width: 500)
						}
					}
					title
				}
			}
		}
	`);

	return (
		<main className='about container'>

			<div className='about--text'>
				<div className='section-title'>
					<h2>{about.title}</h2>
					<p>{about.subtitle}</p>
				</div>
				{about.text.map((item) => (
					<p key={item.id}>{item.text}</p>
				))}
			</div>
			<div className='about--images'>
				{about.images.map((item) => (
					<GatsbyImage
						key={`about-image-${item.id}`}
						className='about--image'
						image={getImage(item.image)}
						alt={item.title}
					/>
				))}
			</div>

		</main>
	);
};

export default About;
